<template>
    <div>{{model.name}}</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: {type: Object, require: true}
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            models: 'host/models',
        }),
        model(){
            return this.models.find(item => this.object.model === item.uid)
        }
    }
}
</script>